import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { apiClient, authorise } from "../../apiClient";
import { ErrorContext } from "../../App";
import {
  ChartViewEnum,
  StatisticsContextInterface,
  StatisticsInterface,
} from "./types";

export const useStatistics = (): StatisticsContextInterface => {
  const { createError, createToast } = useContext(ErrorContext);
  const [year, setYear] = useState<string>(moment().format("YYYY"));
  const [statistics, setStatistics] = useState<StatisticsInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chartView, setChartView] = useState<ChartViewEnum>(
    ChartViewEnum.FinancialPosition
  );
  const getData = async () => {
    await apiClient
      .get(`/api/Statistics/get-statistics?year=${year}`, authorise())
      .then((response) => {
        createToast("Statistics updated successfully");
        setStatistics(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        createError(Array.isArray(error.data) ? error.data : [error.data]);
        setIsLoading(false);
      });
  };

  const handleYearChange = async (year: string) => {
    if (!Number.isNaN(parseInt(year))) {
      setYear(year);
    }
  };
  useEffect(() => {
    const delaySearch = setTimeout(() => getData(), 1000);
    return () => clearTimeout(delaySearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return {
    statistics,
    isLoading,
    handleYearChange,
    year,
    chartView,
    setChartView,
  };
};
