import { Box, Flex, FlexProps, HStack, Spacer, Text } from "@chakra-ui/react";
import React from "react";
interface NameWrapProp extends FlexProps {
  children: React.ReactNode;
  title: string;
  error?: string;
  isRequired?: boolean;
}
export const NameWrap: React.FC<NameWrapProp> = ({
  children,
  title,
  error,
  isRequired,
  ...others
}) => {
  return (
    <Flex w="fit-content" direction={"column"} {...others}>
      <HStack>
        <Box w="fit-content" whiteSpace={"nowrap"}>
          <Text display={"inline"}>{title}</Text>{" "}
          {isRequired && (
            <Text color="red" display={"inline"}>
              *
            </Text>
          )}
        </Box>
        <Spacer />
        <Box color="red.500">{error}</Box>
      </HStack>
      <Box w="100%">{children}</Box>
    </Flex>
  );
};
