import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../apiClient";
import { ErrorContext } from "../../App";
import { isEmpty } from "../../utils/helpers";
import { FormIdDictionary } from "../Settings/UserModal/useUserModal";
import {
  ErrorRegisterForm,
  RegisterContextInterface,
  RegisterPassword,
} from "./types";
export interface RegisterForm {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  reason: string;
}
export const RegisterFormId: FormIdDictionary = {
  firstName: "register-firstName",
  lastName: "register-lastName",
  username: "register-username",
  email: "register-email",
  reason: "register-reason",
  password: "register-password",
  confirmPassword: "register-confirmPassword",
};

const defaultPasswords: RegisterPassword = {
  password: "",
  confirmPassword: "",
};
const defaultError: ErrorRegisterForm = {
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  username: "",
  email: "",
};
export const useRegister = (): RegisterContextInterface => {
  const navigator = useNavigate();
  const { createError, createToast } = useContext(ErrorContext);
  const [data, setData] = useState<RegisterForm>({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    reason: "",
  });
  const [passwords, setPasswords] =
    useState<RegisterPassword>(defaultPasswords);
  const [error, setError] = useState<ErrorRegisterForm>(defaultError);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const validateForm = (): ErrorRegisterForm => {
    const error: ErrorRegisterForm = {
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      username: "",
      email: "",
    };
    if (passwords.password.length < 8) {
      error.password = "Password too short";
    }
    if (passwords.password !== passwords.confirmPassword) {
      error.confirmPassword = "Passwords do not match";
    }
    if (data.firstName.length < 3) {
      error.firstName = "First name too short";
    }
    if (data.lastName.length < 3) {
      error.lastName = "Last name too short";
    }
    if (data.email.length < 3) {
      error.email = "Email too short";
    } else if (!data.email.includes("@")) {
      error.email = "Email is not valid";
    }
    if (data.reason.length > 50) {
      error.reason = "Reason too long";
    }
    error.confirmPassword =
      error.confirmPassword || isEmpty(passwords.confirmPassword);
    error.password = error.password || isEmpty(passwords.password);
    error.firstName = error.firstName || isEmpty(data.firstName);
    error.lastName = error.lastName || isEmpty(data.lastName);
    error.username = error.username || isEmpty(data.username);
    error.email = error.email || isEmpty(data.email);
    return error;
  };

  const handleRegister = async () => {
    const errorObj = validateForm();

    if (Object.keys(errorObj).some((key) => errorObj[key] !== "")) {
      setError(errorObj);
      for (const key in errorObj) {
        if (errorObj.hasOwnProperty(key) && errorObj[key] !== "") {
          const elementId = RegisterFormId[key];
          document.getElementById(elementId || "")?.focus();
          document.getElementById(elementId || "")?.scrollBy();
          break;
        }
      }
    } else {
      setIsLoading(true);
      await apiClient
        .post("/api/register", {
          ...data,
          password: passwords.password,
        })
        .then(() => {
          createToast("Account created successfully");
          setTimeout(() => {
            navigator("/login");
          }, 1000);
        })
        .catch((err) => {
          if (err.response.data.includes("USER_ALREADY_EXISTS"))
            setError({ ...error, username: "Username already exists" });
          createError(Array.isArray(err.data) ? err.data : [err.data]);
        });
    }
    setIsLoading(false);
  };

  const handleLogin = () => {
    navigator("/login");
  };
  const handleData = (value: Partial<RegisterForm>) => {
    setData({ ...data, ...value });
  };

  return {
    data,
    handleData,
    passwords,
    setPasswords,
    error,
    setError,
    isLoading,
    handleRegister,
    handleLogin,
  };
};
