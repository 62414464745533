import { Box, Center, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { StatisticsChart } from "./Chart";
import { StatisticsContext } from "./types";
import { useStatistics } from "./useStatistics";
import { ViewSwitch } from "./ViewSwitch";
import YearSelector from "./YearSelector";

export const StatisticsComponent = () => {
  const hook = useStatistics();
  return (
    <StatisticsContext.Provider value={hook}>
      <VStack p={5} w="100%">
        <VStack w={["100%", "40%"]}>
          <HStack px={[0, 20]} w="100%">
            <Text fontSize="xl">Annual Report</Text>
            <YearSelector />
          </HStack>
          <ViewSwitch />
        </VStack>

        <Center w="100%">
          <Box w={{ base: "90%", md: "70%" }} h="auto">
            <StatisticsChart />
          </Box>
        </Center>
      </VStack>
    </StatisticsContext.Provider>
  );
};
