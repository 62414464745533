import { HStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { isEmpty } from "../../../utils/helpers";
import { NameWrap } from "../NameWrap";
import { VSFDropdown } from "../VSFDropdown";
import {
  depositOptions,
  TransactionFormId,
  TransactionModalContext,
  transactionOptions,
} from "./utils";
export const SecondSection: React.FC = () => {
  const {
    error,
    data,
    handleDataChange,
    handleDataExchangeChange,
    handleErrorChange,
  } = useContext(TransactionModalContext);

  const handleTypeChange = (value: string) => {
    handleDataChange({ type: parseInt(value) });
    if (value === "20") {
      handleDataExchangeChange({ type: 10 });
    } else {
      handleDataExchangeChange({ type: 20 });
    }
    handleErrorChange({ type: isEmpty(value) });
  };
  const handleDepositChange = (value: string) => {
    handleDataChange({ deposit: parseInt(value) });
    if (value === "20") {
      handleDataExchangeChange({ deposit: 10 });
    } else {
      handleDataExchangeChange({ deposit: 20 });
    }

    handleErrorChange({ deposit: isEmpty(value) });
  };
  return (
    <>
      {" "}
      <HStack w="100%">
        <NameWrap title="Type" w="100%" error={error.type}>
          <VSFDropdown
            isRequired
            id={TransactionFormId.type}
            value={data.type?.toString() || ""}
            onChange={handleTypeChange}
            error={error.type}
            options={transactionOptions}
            placeholder="Select transaction type"
          />
        </NameWrap>
        <NameWrap title="Deposit" w="100%" error={error.deposit}>
          <VSFDropdown
            isRequired
            id={TransactionFormId.deposit}
            value={data.deposit?.toString() || ""}
            onChange={handleDepositChange}
            options={depositOptions}
            error={error.deposit}
            placeholder="Select deposit type"
          />
        </NameWrap>
      </HStack>
    </>
  );
};
