import { Flex, ModalBody, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { CustomSpinner } from "../CustomSpinner";
import { ExchangeSection } from "./ExchangeSection";
import { FirstSection } from "./FirstSection";
import { SecondSection } from "./SecondSection";
import { ThirdSection } from "./ThirdSection";
import { TimeSection } from "./TimeSection";
import { TransactionModalContext } from "./utils";

export const TransactionModalBody: React.FC = () => {
  const { isLoading } = useContext(TransactionModalContext);

  return (
    <>
      {" "}
      <ModalBody maxH={"70vh"} overflow="scroll">
        {isLoading ? (
          <Flex justify="center" w="100%">
            <CustomSpinner />
          </Flex>
        ) : (
          <VStack spacing="0" position={"relative"}>
            <FirstSection />
            <SecondSection />
            <ThirdSection />

            <ExchangeSection />
            <TimeSection />
          </VStack>
        )}
      </ModalBody>
    </>
  );
};
