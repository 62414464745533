import { CheckIcon } from "@chakra-ui/icons";
import { Box, Flex, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiClient } from "../../apiClient";
import { ErrorContext } from "../../App";
import { VSFButton } from "../common/VSFButton";
import { AuthenticationWrapper } from "../Dashboard/AuthenticationWrapper";
export const ConfirmEmail: React.FC = () => {
  const { token, email } = useParams();
  const { createError, createToast } = useContext(ErrorContext);
  const confirmEmail = async () => {
    await apiClient
      .get(`/api/confirm-email?email=${email}&token=${token}`)
      .then(() => {
        createToast("Your email has been confirmed");
      })
      .catch((err) => {
        createError(err.data);
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    confirmEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AuthenticationWrapper>
        <VStack bg="white" py={[8, 0]} px={[2, 0]}>
          <Flex
            flexDirection={["column", "row"]}
            align="center"
            justify={"center"}
          >
            <CheckIcon color="green.200" boxSize={20} />
            <Box fontSize={20} fontWeight="bold" textAlign={"center"}>
              Your email has been succesufully confirmed
            </Box>
          </Flex>
          <VSFButton
            onClick={() => navigate("/login")}
            bg="green.600"
            colorScheme={"green"}
          >
            Go to login
          </VSFButton>
        </VStack>
      </AuthenticationWrapper>
    </>
  );
};
