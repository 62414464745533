import React, { useContext, useEffect, useState } from "react";
import { apiClient, authorise } from "../../../apiClient";
import { isEmpty, isNumeric } from "../../../utils/helpers";
import { NameWrap } from "../NameWrap";
import { SelectOptionsInterface } from "../VSFDropdown";
import { VSFInput } from "../VSFInput";
import { VSFSearchSelect } from "../VSFSearchSelect";
import { TransactionFormId, TransactionModalContext } from "./utils";
export const ThirdSection: React.FC = () => {
  const { error, data, handleDataChange, handleErrorChange } = useContext(
    TransactionModalContext
  );
  const [optionsCategory, setOptionsCategory] = useState<
    SelectOptionsInterface[]
  >([]);

  const getCategories = async () => {
    await apiClient
      .get("/api/Transaction/get-transaction-existing-categories", authorise())
      .then((res) => {
        const data = res.data;
        const options = data.map((item: any) => {
          return { value: item, display: item };
        });
        setOptionsCategory(options);
      });
  };

  const handleCategoryChange = (value?: string) => {
    handleDataChange({ category: value });
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDataChange({ amount: e.target.value });
    handleErrorChange({
      amount: isEmpty(e.target.value) || isNumeric(e.target.value),
    });
  };

  useEffect(() => {
    getCategories();
  }, []);
  return (
    <>
      {" "}
      <NameWrap title="Category" w="100%" error={error.category}>
        <VSFSearchSelect
          id={TransactionFormId.category}
          value={data.category?.toString() || ""}
          onChange={handleCategoryChange}
          options={optionsCategory}
          error={error.category}
          placeholder="Select category type"
        />
      </NameWrap>
      <NameWrap title="Amount" error={error.amount} w="100%">
        <VSFInput
          isRequired
          id={TransactionFormId.amount}
          placeholder="Transaction amount"
          onChange={handleAmountChange}
          error={error.amount}
          value={data.amount?.toString()}
        />
      </NameWrap>
    </>
  );
};
