import { HStack, Spacer, Switch } from "@chakra-ui/react";
import React, { useContext } from "react";
import { isEmpty } from "../../../utils/helpers";
import { NameWrap } from "../NameWrap";
import { VSFInput } from "../VSFInput";
import { VSFTextArea } from "../VSFTextArea";
import { TransactionFormId, TransactionModalContext } from "./utils";

export const FirstSection: React.FC = () => {
  const { error, data, handleDataChange, handleErrorChange, idProps } =
    useContext(TransactionModalContext);
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDataChange({ name: e.target.value });
    handleErrorChange({ name: isEmpty(e.target.value) });
  };
  const handleDescriptionChange = (value: string) => {
    handleDataChange({ description: value });
  };

  return (
    <>
      {" "}
      <HStack w="100%" h="100%">
        <NameWrap title="Name" w="100%" error={error.name}>
          <VSFInput
            isRequired
            id={TransactionFormId.name}
            error={error.name}
            value={data?.name}
            placeholder="Transaction name"
            onChange={handleNameChange}
          />
        </NameWrap>
        <Spacer />
        <NameWrap title="Reccurent" pt={2}>
          <Switch
            onChange={(e) => {
              handleErrorChange({ frequency: "" });
              handleDataChange({ isRecurent: e.target.checked });
            }}
            isDisabled={!!idProps}
            isChecked={data.isRecurent}
            size="lg"
            pl={4}
            colorScheme="teal"
          />
        </NameWrap>
      </HStack>
      <NameWrap title="Description" w="100%">
        <VSFTextArea
          id={TransactionFormId.description}
          value={data?.description || ""}
          placeholder="Transaction description"
          onChange={handleDescriptionChange}
        />
      </NameWrap>
    </>
  );
};
