import moment from "moment";
import React, { useContext, useMemo } from "react";
import { Chart } from "react-chartjs-2";
import { CustomSpinner } from "../common/CustomSpinner";
import { ChartViewEnum, StatisticsContext } from "./types";

const optionsMonthlyOverview = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: "Monthly Overview",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
const optionsFinancialPosition = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: "Financial Position ",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      stacked: true,
    },
    x: {
      stacked: true,
    },
    "y-percent": {
      type: "linear" as const,
      display: true,
      position: "right" as const,
      id: "y-percent",

      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        callback: function (value: any, index: any, values: any) {
          return value + "%";
        },
      },
    },
  },
};

export const StatisticsChart: React.FC = () => {
  const { statistics, isLoading, chartView } = useContext(StatisticsContext);

  if (isLoading) {
    return <CustomSpinner />;
  }
  const financialPositionData = useMemo(
    () => [
      {
        type: "bar" as const,
        label: "Current Cash",
        backgroundColor: "rgba(33, 186, 69, 0.7)",
        data: statistics.map((stat) => stat.currentCash),
      },
      {
        type: "bar" as const,
        label: "Current Bank",
        backgroundColor: "rgba(30, 144, 255, 0.7)",
        data: statistics.map((stat) => stat.currentBank),
      },
      {
        type: "bar" as const,
        label: "Current Binance Balance",
        backgroundColor: "rgba(255, 159, 64, 0.7)",
        data: statistics.map((stat) => stat.currentBinanceBalanceInRon),
      },
      {
        type: "line" as const,
        label: "Change Percent",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 4,
        yAxisID: "y-percent",
        fill: false,
        data: statistics.map((stat) => stat.changePercent),
      },
    ],
    [statistics]
  );

  const monthlyOverviewData = useMemo(
    () => [
      {
        type: "bar" as const,
        label: "Total Expenses",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        data: statistics.map((stat) => stat.totalExpenses),
      },
      {
        type: "bar" as const,
        label: "Total Incomes",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        data: statistics.map((stat) => stat.totalIncomes),
      },
      {
        type: "line" as const,
        label: "Current Binance Balance",
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 6,
        fill: false,
        data: statistics.map((stat) => stat.currentBinanceBalanceInRon),
      },
    ],
    [statistics]
  );
  const chartDataMonthlyOverview = useMemo(
    () => ({
      labels: statistics.map((stat) => moment(stat.date).format("MMM YYYY")),
      datasets: monthlyOverviewData,
    }),
    [monthlyOverviewData, statistics]
  );

  const chartDataFinancialPosition = useMemo(
    () => ({
      labels: statistics.map((stat) => moment(stat.date).format("MMM YYYY")),
      datasets: financialPositionData,
    }),
    [financialPositionData, statistics]
  );

  return (
    <>
      {chartView === ChartViewEnum.FinancialPosition ? (
        <Chart
          type="bar"
          data={chartDataFinancialPosition}
          options={optionsFinancialPosition}
        />
      ) : (
        <Chart
          type="bar"
          data={chartDataMonthlyOverview}
          options={optionsMonthlyOverview}
        />
      )}
    </>
  );
};
