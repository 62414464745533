import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { apiClient, authorise } from "../../apiClient";
import { ErrorContext } from "../../App";
import { TransactionInterface } from "../common/Table/types";
import { HistoryContextInterface, HistoryFilter } from "./types";

export const useHistory = (): HistoryContextInterface => {
  const { createToast, createError } = useContext(ErrorContext);
  const [transactions, setTransactions] = useState<TransactionInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<HistoryFilter>({
    text: null,
    startDate: null,
    endDate: null,
  });
  const [isMore, setIsMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const take = useMemo(() => 15, []);
  const [id, setId] = useState<string | undefined>(undefined);
  const handleFilterChange = (data: Partial<HistoryFilter>) => {
    console.log(data);
    setFilter({ ...filter, ...data });
  };
  const getData = async (noSkip?: boolean) => {
    setIsLoadingMore(true);
    if (noSkip) setSkip(0);
    else setSkip(skip + 15);
    await apiClient
      .post(
        `/api/UserHistory/get-history?skip=${noSkip ? skip : 0}&take=${take}`,
        filter,
        authorise()
      )
      .then((res) => {
        if (res.data.length < 15) setIsMore(false);
        if (!noSkip) setTransactions([...transactions, ...res.data]);
        else setTransactions(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        createError(err.response.data);
        setIsLoading(false);
      });
    setIsLoadingMore(false);
  };

  const submit = async (data: TransactionInterface) => {
    if (id) {
      await apiClient
        .put(`/api/Transaction/update-transaction?id=${id}`, data, authorise())
        .then((res) => {
          createToast("Transaction updated");
          getData();
        })
        .catch((err) => {
          createError(err.response.data);
        });
    } else {
      data.isRecurent
        ? await apiClient
            .post(
              "/api/Transaction/create-recurrent-transaction",
              data,
              authorise()
            )
            .then((res) => {
              createToast("Recurrent transaction created");
            })
            .catch((err) => {
              createError(err.response.data);
            })
        : await apiClient
            .post("/api/Transaction/create-transaction", data, authorise())
            .then((res) => {
              createToast("Transaction created");

              setTransactions(
                [...transactions, res.data].sort((a, b) =>
                  moment(a.date, "DD/MM/YYYY").diff(
                    moment(b.date, "DD/MM/YYYY")
                  ) >= 0
                    ? -1
                    : 1
                )
              );
            })
            .catch((err) => {
              createError(err.response.data);
            });
    }
  };
  const handleEdit = (id: string) => {
    setId(id);
  };
  const handleDelete = async (id: string) => {
    await apiClient
      .delete(`/api/Transaction/delete-transaction?id=${id}`, authorise())
      .then((res) => {
        createToast("Transaction deleted");
        getData();
      })
      .catch((err) => {
        createError(err.response.data);
      });
  };
  useEffect(() => {
    if (isLoading === true) getData(true);
    else {
      setIsLoading(true);
      const debounced = setTimeout(() => {
        getData(true);
      }, 1000);
      return () => clearTimeout(debounced);
    }
  }, [filter]);

  return {
    getData,
    transactions,
    submit,
    isLoadingMore,
    id,
    isMore,
    isLoading,
    handleEdit,
    handleDelete,
    setId,
    handleFilterChange,
    filter,
  };
};
