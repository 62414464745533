import { createContext } from "react";

export interface StatisticsInterface {
  id: string;
  date: string;
  totalExpenses: number;
  totalIncomes: number;
  currentCash: number;
  currentBank: number;
  changePercent: number;
  currentBinanceBalanceInRon: number;
}
export interface StatisticsContextInterface {
  statistics: StatisticsInterface[];
  isLoading: boolean;
  handleYearChange: (year: string) => void;
  year: string;
  chartView: ChartViewEnum;
  setChartView: (view: ChartViewEnum) => void;
}
export const StatisticsContext = createContext<StatisticsContextInterface>(
  {} as StatisticsContextInterface
);
export enum ChartViewEnum {
  MonthlyOverview,
  FinancialPosition,
}
