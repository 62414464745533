import React, { useContext } from "react";
import { isEmpty } from "../../../utils/helpers";
import { NameWrap } from "../NameWrap";
import { VSFDatePicker } from "../VSFDatePicker";
import { VSFDropdown } from "../VSFDropdown";
import {
  frequencyOptions,
  TransactionFormId,
  TransactionModalContext,
} from "./utils";
export const TimeSection: React.FC = () => {
  const { error, data, handleDataChange, handleErrorChange } = useContext(
    TransactionModalContext
  );
  const handleDateChange = (value: string | null) => {
    handleDataChange({ date: value ?? "" });
    handleErrorChange({ date: isEmpty(value ?? "") });
  };

  const handleFrequencyChange = (value: string) => {
    handleDataChange({ frequency: parseInt(value) });
    handleErrorChange({ frequency: isEmpty(value) });
  };
  return (
    <>
      {" "}
      <NameWrap title="Date" w="100%">
        <VSFDatePicker
          isRequired
          id={TransactionFormId.date}
          value={data.date}
          onChange={handleDateChange}
        />
      </NameWrap>
      <NameWrap title="Frequency" w="100%" error={error.frequency}>
        <VSFDropdown
          isRequired
          id={TransactionFormId.frequency}
          value={data.frequency?.toString() || ""}
          onChange={handleFrequencyChange}
          options={frequencyOptions}
          error={error.frequency}
          placeholder="Select frequency type"
        />
      </NameWrap>
    </>
  );
};
