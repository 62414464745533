import { HStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { isEmpty, isNumeric } from "../../../utils/helpers";
import { NameWrap } from "../NameWrap";
import { VSFDropdown } from "../VSFDropdown";
import { VSFInput } from "../VSFInput";
import {
  depositOptions,
  TransactionFormId,
  TransactionModalContext,
  transactionOptions,
} from "./utils";
export const ExchangeSection: React.FC = () => {
  const {
    isExchange,
    error,
    dataExchange,
    handleDataExchangeChange,
    handleErrorChange,
  } = useContext(TransactionModalContext);
  const handleAmountExchangeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleDataExchangeChange({ amount: e.target.value });
    handleErrorChange({
      amount: isEmpty(e.target.value) || isNumeric(e.target.value),
    });
  };
  return (
    <>
      {" "}
      {isExchange ? (
        <>
          <HStack w="100%">
            <NameWrap title="Type" w="100%" error={error.type}>
              <VSFDropdown
                isRequired
                id={TransactionFormId.type}
                value={dataExchange.type?.toString() || ""}
                error={error.type}
                options={transactionOptions}
                placeholder="Select transaction type"
              />
            </NameWrap>
            <NameWrap title="Deposit" w="100%" error={error.deposit}>
              <VSFDropdown
                isRequired
                id={TransactionFormId.deposit}
                value={dataExchange.deposit?.toString() || ""}
                isDisabled
                options={depositOptions}
                error={error.deposit}
                placeholder="Select deposit type"
              />
            </NameWrap>
          </HStack>
          <NameWrap title="Amount" error={error.amount} w="100%">
            <VSFInput
              isRequired
              id={TransactionFormId.amount}
              placeholder="Transaction amount"
              onChange={handleAmountExchangeChange}
              error={error.amount}
              value={dataExchange.amount?.toString()}
            />
          </NameWrap>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
