import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../apiClient";
import { ErrorContext } from "../../App";
import { ForgotPasswordContext } from "./types";

export const useForgotPassword = (): ForgotPasswordContext => {
  const navigate = useNavigate();
  const { createError, createToast } = useContext(ErrorContext);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const submit = async () => {
    if (!email) {
      setError("Email is required");
      return;
    } else if (!email.includes("@")) {
      setError("Email is invalid");
      return;
    } else {
      setIsLoading(true);
      await apiClient
        .get(`/api/forgot-password?email=${email}`, {})
        .then(() => {
          createToast("Email sent");
          setTimeout(() => {
            navigate("/forgot-password/");
          }, 500);
        })
        .catch((error) => {
          createError(Array.isArray(error.data) ? error.data : [error.data]);
        });
      setIsLoading(false);
    }
  };
  return {
    email,
    setEmail,
    submit,
    isLoading,
    error,
    setError,
  };
};
