import { ChevronDownIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionItem,
  Box,
  Flex,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { NameWrap } from "../common/NameWrap";
import { VSFButton } from "../common/VSFButton";
import { VSFDatePicker } from "../common/VSFDatePicker";
import { VSFInput } from "../common/VSFInput";
import { TransactionModal } from "../common/VSFTransactionModal";
import { TransactionRow } from "../Home/TransactionRow";
import { useHistory } from "./useHistory";
export const HistoryComponent: React.FC = () => {
  const {
    transactions,
    submit,
    id,
    handleDelete,
    handleEdit,
    getData,
    isMore,
    isLoadingMore,
    setId,
    filter,
    handleFilterChange,
    isLoading,
  } = useHistory();
  const [indexes, setIndexes] = React.useState<number[]>([]);
  const handleIndexChange = (index: number) => {
    if (indexes.includes(index)) {
      setIndexes(
        indexes.filter((i) => Math.floor(i / 2) !== Math.floor(index / 2))
      );
    } else {
      setIndexes([...indexes, index, index % 2 === 0 ? index + 1 : index - 1]);
    }
  };
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterChange({ text: e.target.value });
  };
  const handleStartDateChange = (date: string | null) => {
    handleFilterChange({ startDate: date });
  };
  const handleEndDateChange = (date: string | null) => {
    handleFilterChange({ endDate: date });
  };

  return (
    <>
      <VStack align={"flex-start"} w="100%" px={[2, 24]} id="history" pb={6}>
        <HStack w="100%" py={8} align="center">
          <Heading>History</Heading>
          <Spacer />
          <Box>
            <TransactionModal
              submitProps={submit}
              idProps={id}
              setIdProps={setId}
            />
          </Box>
        </HStack>
        <VStack w={["100%", "40%"]}>
          <NameWrap title="Filter" w="100%">
            <VSFInput
              value={filter.text ?? ""}
              placeholder="Search for a word.."
              onChange={handleTextChange}
            />
          </NameWrap>
          <HStack w="100%">
            <NameWrap title="Start date" w="50%">
              <VSFDatePicker
                value={filter.startDate ?? ""}
                onChange={handleStartDateChange}
              />
            </NameWrap>
            <NameWrap title="End date" w="50%">
              <VSFDatePicker
                value={filter.endDate ?? ""}
                onChange={handleEndDateChange}
              />
            </NameWrap>
          </HStack>
        </VStack>
        <Accordion allowToggle w={"100%"} h="100%">
          {transactions.length === 0 && !isLoading ? (
            <>
              <Heading
                w="100%"
                alignItems={"center"}
                display="flex"
                justifyContent={"center"}
              >
                <InfoOutlineIcon mr={2} />
                <Text fontSize={[16, 24]}>You have no transaction!</Text>
              </Heading>
            </>
          ) : (
            <>
              {transactions.map((transaction, index) => (
                <AccordionItem
                  key={transaction.id}
                  w="100%"
                  onClick={() => {
                    handleIndexChange(index);
                  }}
                  border="2px solid"
                  pb={[2, 0]}
                  mb={2}
                  bg={transaction.type === 10 ? "lightGreen.1" : "red.10"}
                  borderRadius={12}
                >
                  <TransactionRow
                    transaction={transaction}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </AccordionItem>
              ))}
            </>
          )}
        </Accordion>
        <Flex w="100%" justify={"center"}>
          {!isMore ? (
            <></>
          ) : (
            <VSFButton onClick={() => getData(true)} isLoading={isLoadingMore}>
              Load More <ChevronDownIcon boxSize={8} />
            </VSFButton>
          )}
        </Flex>
      </VStack>
    </>
  );
};
