import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { apiClient, authorise } from "../../../apiClient";
import { isEmpty, isNumeric } from "../../../utils/helpers";
import { TransactionInterface } from "../Table/types";
import { VSFButton } from "../VSFButton";
import { SelectOptionsInterface } from "../VSFDropdown";
import { TransactionModalBody } from "./Body";
import { TransactionModalFooter } from "./Footer";
import { TransactionModalHeader } from "./Header";
import { useTransaction } from "./useTransaction";
import { TransactionModalContext } from "./utils";

export interface TransactionModalProps {
  submitProps: (data: TransactionInterface) => void;
  idProps?: string;
  setIdProps?: (id: string) => void;
}

export const TransactionModal: React.FC<TransactionModalProps> = ({
  submitProps,
  idProps,
  setIdProps,
}) => {
  const hook = useTransaction(submitProps, idProps, setIdProps);

  return (
    <>
      <VSFButton onClick={hook.onOpen} w="100%">
        Add transaction
      </VSFButton>
      <Modal isOpen={hook.isOpen} onClose={hook.reset} size="2xl">
        <ModalOverlay />
        <ModalContent bg={"white"} position="relative">
          <TransactionModalContext.Provider value={hook}>
            <form onSubmit={hook.submit}>
              <TransactionModalHeader isEdit={!!idProps} />
              <TransactionModalBody />
              <TransactionModalFooter isEdit={!!idProps} />
            </form>
          </TransactionModalContext.Provider>
        </ModalContent>
      </Modal>
    </>
  );
};
