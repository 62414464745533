import { Box, Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { isEmpty } from "../../utils/helpers";
import { NameWrap } from "../common/NameWrap";
import { VSFButton } from "../common/VSFButton";
import { VSFInput } from "../common/VSFInput";
import { VSFPasswordInput } from "../common/VSFPasswordInput";
import { AuthenticationWrapper } from "../Dashboard/AuthenticationWrapper";
import { useChangePassword } from "./useChangePassword";
export const ChangePassword: React.FC = () => {
  const {
    token,
    passwords,
    setPasswords,
    setToken,
    submit,
    error,
    setError,
    isLoading,
  } = useChangePassword();
  const handleChangeToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (token.length <= 8) {
      setToken(e.target.value);

      setError({ ...error, token: isEmpty(e.target.value) });
    } else setError({ ...error, token: "Only 8 characters long" });
  };
  const handleChangePassword = (e: string) => {
    setPasswords({ ...passwords, password: e });
    setError({
      ...error,
      passwords: { ...error.passwords, password: isEmpty(e) },
    });
  };
  const handleChangeConfirmPassword = (e: string) => {
    setPasswords({ ...passwords, confirmPassword: e });
    setError({
      ...error,
      passwords: { ...error.passwords, confirmPassword: isEmpty(e) },
    });
  };

  return (
    <>
      <AuthenticationWrapper>
        <>
          <VStack
            overflow={"auto"}
            align="center"
            bg="white"
            p={4}
            position={"relative"}
            border="2px solid"
            borderColor={"purple.400"}
            borderRadius={4}
            // p={4}
          >
            <Flex
              p={4}
              px={20}
              position="sticky"
              top={0}
              w="100%"
              direction="column"
              align={"center"}
              bg="white"
              zIndex={2}
            >
              <Box
                fontSize={[16, 20]}
                whiteSpace="nowrap"
                w="100%"
                fontWeight={"bold"}
              >
                Change password
              </Box>
            </Flex>
            <NameWrap title="Token" error={error.token} w="100%" isRequired>
              <VSFInput
                value={token}
                error={error.token}
                onChange={handleChangeToken}
                placeholder="Enter token"
              />
            </NameWrap>
            <NameWrap
              w="100%"
              title="Password"
              error={error.passwords.password}
              isRequired
            >
              <VSFPasswordInput
                handlePasswordChange={handleChangePassword}
                password={passwords.password}
                error={error.token}
                placeholder="Enter password"
              />
            </NameWrap>
            <NameWrap
              w="100%"
              title="Confirm password"
              error={error.passwords.confirmPassword}
              isRequired
            >
              <VSFPasswordInput
                password={passwords.confirmPassword}
                error={error.passwords.confirmPassword}
                handlePasswordChange={handleChangeConfirmPassword}
                placeholder="Enter confirm password"
              />
            </NameWrap>
            <Flex
              position={"sticky"}
              bottom="0"
              bg="white"
              align="center"
              direction={"column"}
              w="100%"
            >
              <VSFButton onClick={submit} isLoading={isLoading} w="90%" py={2}>
                Submit
              </VSFButton>
            </Flex>
          </VStack>
        </>
      </AuthenticationWrapper>
    </>
  );
};
