import { SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  Icon,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { ReactComponent as History } from "../../Assets/Icons/History.svg";
import { ReactComponent as Home } from "../../Assets/Icons/Home.svg";
import { ReactComponent as Statistics } from "../../Assets/Icons/Statistics.svg";
import { CopyRightModal } from "./CopyRightModal";
interface BottomBarItemProps {
  text: string;
  url: string;
  children: React.ReactNode;
}
export const BottomBar: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const handleNavigate = (url: string) => {
    navigate(url);
  };
  const location = useLocation();
  const BottomBarItem: React.FC<BottomBarItemProps> = ({
    text,
    children,
    url,
  }) => {
    return (
      <>
        <VStack
          spacing={0}
          h="100%"
          onClick={() => handleNavigate(url)}
          opacity={location.pathname === url ? 1 : 0.5}
        >
          <Box>{children}</Box>
          <Spacer />
          <Box fontSize={12} fontWeight="bold">
            {text}
          </Box>
        </VStack>
      </>
    );
  };
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <VStack
        spacing={0}
        w="100%"
        p={0}
        bottom={0}
        zIndex={10}
        position={["fixed", "static"]}
      >
        {!location.pathname.includes("login") &&
          !location.pathname.includes("register") &&
          !location.pathname.includes("forgot") && (
            <HStack
              pt={2}
              bg="white"
              display={["flex", "none"]}
              justify="space-evenly"
              w="100%"
              borderTop={"4px solid"}
              borderColor="purple.500"
            >
              {user?.userRole === 20 ? (
                <>
                  <BottomBarItem text="Home" url="/home">
                    <Icon as={Home} boxSize={8} />
                  </BottomBarItem>
                  <BottomBarItem text="History" url="/history">
                    <Icon as={History} boxSize={8} fill="purple.500" />
                  </BottomBarItem>
                  <BottomBarItem text="Statistics" url="/statistics">
                    <Icon as={Statistics} boxSize={8} />
                  </BottomBarItem>
                  <BottomBarItem text="Settings" url="/settings">
                    <Icon as={SettingsIcon} boxSize={8} />
                  </BottomBarItem>
                </>
              ) : (
                <></>
              )}
            </HStack>
          )}
        <Box textAlign={"center"} bg="grey.300" w="100%">
          &#169; Copyright -{" "}
          <Text
            onClick={() => onOpen()}
            cursor={"pointer"}
            textDecoration="underline"
            color="purple.400"
            display={"inline-block"}
          >
            Terms and conditions
          </Text>
        </Box>
      </VStack>
      <CopyRightModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
