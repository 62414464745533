import { Text } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { default as StateManagedSelect, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { theme } from "../../theme";
import { SelectOptionsInterface } from "./VSFDropdown";

interface VSFSearchProps extends Omit<StateManagedSelect, "onChange"> {
  id?: string;
  onChange: (val: string) => void;
  error?: string;
  options: SelectOptionsInterface[];
  value: string;
  placeholder?: string;
  isDisabled?: boolean;
}
export interface SearchSelectInterface {
  value: string;
  label: string;
}
export const VSFSearchSelect: React.FC<VSFSearchProps> = (props) => {
  const neutralGrey = theme.colors.grey[300];
  const purple500 = theme.colors.purple[500];
  const { onChange, options, error, value, ...others } = props;
  const [didMount, setDidMount] = useState<boolean>(false);

  const [optionsValues, setOptionsValues] = useState<SearchSelectInterface[]>(
    options.map((option) => {
      return { value: option.value, label: option.display };
    })
  );
  const [selectValue, setSelectValue] = useState<
    SingleValue<SearchSelectInterface>
  >(
    optionsValues.filter((option) => option.value === value)[0]
      ? optionsValues.filter((option) => option.value === value)[0]
      : null
  );

  useEffect(() => {
    setDidMount(true);
  }, []);

  const handleChange = (newValue: SingleValue<SearchSelectInterface>) => {
    setSelectValue({
      value: newValue?.value || "",
      label: newValue?.label || "",
    });
    const newOptionsValues = optionsValues.filter((option) => {
      return option.value === newValue?.value;
    });

    setOptionsValues(newOptionsValues);
  };
  useEffect(() => {
    setOptionsValues(
      options.map((option) => {
        return { value: option.value, label: option.display };
      })
    );
  }, [options]);

  useEffect(() => {
    if (didMount) {
      onChange(selectValue?.value ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue]);

  const customStyles = {
    singleValue: (provided: any, state: any) => {
      const textColor = state.isSelected ? purple500 : "#000";
      return { ...provided, color: textColor };
    },
    option: (provided: any) => ({
      ...provided,
      color: "black",
    }),
    control: (provided: any, state: any) => {
      return {
        ...provided,
        color: "#000",
        minHeight: "52px",
        border: state.isFocused
          ? `2px solid ${purple500}`
          : `1px solid ${neutralGrey}`,
        "&:hover": {
          border: state.isFocused
            ? `2px solid ${purple500}`
            : `1px solid ${neutralGrey}`,
        },
      };
    },
  };

  return (
    <>
      <CreatableSelect
        isDisabled={props.isDisabled ?? false}
        {...others}
        options={optionsValues}
        onChange={handleChange}
        styles={customStyles}
        value={selectValue}
      ></CreatableSelect>
      {error && <Text color={"danger.500"}>{error}</Text>}
    </>
  );
};
