import { useContext } from "react";
import { SelectOptionsInterface, VSFDropdown } from "../common/VSFDropdown";
import { StatisticsContext } from "./types";

const currentYear = new Date().getFullYear();
const startYear = 2023;
const years: SelectOptionsInterface[] = Array.from(
  { length: currentYear - startYear + 1 },
  (_, index): SelectOptionsInterface => {
    const yearValue = startYear + index;
    return {
      value: yearValue.toString(),
      display: yearValue.toString(),
    };
  }
);
const YearSelector = () => {
  const { year, handleYearChange } = useContext(StatisticsContext);
  return (
    <VSFDropdown
      placeholder="Select year"
      options={years}
      value={year}
      variant="filled"
      onChange={handleYearChange}
    />
  );
};

export default YearSelector;
