import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import { BottomBar } from "./BottomBar";
import { NavBar } from "./NavBar";

interface DashboardWrapperProps {
  children: React.ReactNode;
}

export const DashboardWrapper: React.FC<DashboardWrapperProps> = ({
  children,
}) => {
  return (
    <VStack
      overflow="hidden"
      h="100vh"
      width={"100%"}
      spacing={0}
      position="relative"
    >
      <NavBar />
      <Box
        pt={["64px", 0]}
        w="100%"
        h={["calc(100% - 64px)", "100%"]}
        overflowY={"scroll"}
        position="relative"
      >
        {children}
      </Box>
      <BottomBar />
    </VStack>
  );
};
