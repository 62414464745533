import { HStack, ModalFooter, Spacer } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { VSFButton } from "../VSFButton";
import { TransactionModalContext } from "./utils";
interface TransactionModalFooterProps {
  isEdit: boolean;
}
export const TransactionModalFooter: React.FC<TransactionModalFooterProps> = ({
  isEdit,
}) => {
  const {
    submit,
    isSubmitting,
    toggleExchange,
    isExchange,
    data,
    handleDataExchangeChange,
  } = useContext(TransactionModalContext);

  useEffect(() => {
    if (data.type === 10) {
      handleDataExchangeChange({ type: 20 });
    } else {
      handleDataExchangeChange({ type: 10 });
    }
    if (data.deposit === 10) {
      handleDataExchangeChange({ deposit: 20 });
    } else {
      handleDataExchangeChange({ deposit: 10 });
    }
  }, [isExchange]);
  return (
    <ModalFooter>
      <HStack
        pt={2}
        w="100%"
        h={12}
        justify="flex-end"
        position={"sticky"}
        bottom={0}
        zIndex={2}
        bg="white"
      >
        <VSFButton onClick={toggleExchange} type="button">
          Exchange
        </VSFButton>
        <Spacer />
        <VSFButton onClick={submit} isLoading={isSubmitting} type="submit">
          {isEdit ? "Update" : "Submit"}
        </VSFButton>
      </HStack>
    </ModalFooter>
  );
};
