import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { apiClient } from "../../apiClient";
import { ErrorContext } from "../../App";
import { RegisterPassword } from "../Register/types";
import { ChangePasswordContext } from "./types";
export interface ErrorChangePasswordForm {
  token: string;
  passwords: RegisterPassword;
}
export const useChangePassword = (): ChangePasswordContext => {
  const { createError, createToast } = useContext(ErrorContext);
  const { token: tokenProp } = useParams();
  const [token, setToken] = useState<string>(tokenProp || "");
  const [passwords, setPasswords] = useState<RegisterPassword>({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState<ErrorChangePasswordForm>({
    token: "",
    passwords: {
      password: "",
      confirmPassword: "",
    },
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const createErrorObject = (): ErrorChangePasswordForm => {
    const error: ErrorChangePasswordForm = {
      token: "",
      passwords: {
        password: "",
        confirmPassword: "",
      },
    };
    if (token.length !== 8) error.token = "Token must be 8 characters long";
    if (passwords.password.length < 8)
      error.passwords.password = "Password must be at least 8 characters";
    if (passwords.password !== passwords.confirmPassword)
      error.passwords.confirmPassword = "Passwords do not match";
    return error;
  };
  const validate = (): boolean => {
    const error = createErrorObject();
    setError(error);
    return (
      error.token.length === 0 &&
      error.passwords.password.length === 0 &&
      error.passwords.confirmPassword.length === 0
    );
  };

  const submit = async () => {
    if (!validate()) return;
    else {
      setIsLoading(true);
      await apiClient
        .get(
          `/api/reset-password?token=${token}&password=${passwords.password}`,
          {}
        )
        .then(() => {
          createToast("Password has been reset");
        })
        .catch((error) => {
          if (error.data.includes("TOKEN_EXPIRED"))
            setError({
              ...error,
              token: "Token has expired",
            });
          else if (error.data.includes("INVALID_TOKEN"))
            setError({
              ...error,
              token: "Invalid token",
            });
          createError(error.data);
        });
      setIsLoading(false);
    }
  };
  return {
    token,
    passwords,
    submit,
    isLoading,
    setPasswords,
    setToken,
    error,
    setError,
  };
};
