import { Box, Flex, HStack, Image } from "@chakra-ui/react";
import React from "react";
import { NavBarHeight } from "./NavBar";
interface AuthenticationWrapperProps {
  children: React.ReactNode;
}
export const AuthenticationWrapper: React.FC<AuthenticationWrapperProps> = ({
  children,
}) => {
  return (
    <>
      <HStack
        maxH={`calc(100vh - ${NavBarHeight + 10}px)`}
        w="100%"
        spacing={0}
      >
        <Box minW="fit-content" position={["absolute", "initial"]} top={0}>
          <Image
            src={"/BannerPurple.png"}
            h={`calc(100vh - ${NavBarHeight + 73}px)`}
          />
        </Box>
        <Flex
          justify="center"
          pt={10}
          w={["100%", "50%"]}
          bg="transparent"
          maxH={"80vh"}
          px={[2, 0]}
          zIndex={2}
        >
          {children}
        </Flex>
      </HStack>
    </>
  );
};
