import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionItem,
  Box,
  Flex,
  Grid,
  Heading,
  HStack,
  Text,
  Spacer,
  useMediaQuery,
  VStack,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import moment from "moment";
import React, { Fragment, useContext, useEffect } from "react";
import { HomeContext } from ".";
import { UserContext } from "../../App";
import { CustomSpinner } from "../common/CustomSpinner";

import { TransactionModal } from "../common/VSFTransactionModal";
import { TransactionRow } from "./TransactionRow";
export const Home: React.FC = () => {
  const { user, loginJWT } = useContext(UserContext);
  const clientJWT = localStorage.getItem("clientJWT");
  const {
    transactions,
    submit,
    isLoading,
    id,
    handleDelete,
    handleEdit,
    setId,
    userExpenseAnalysis,
  } = useContext(HomeContext);
  const [indexes, setIndexes] = React.useState<number[]>([]);
  const handleIndexChangeMobile = (index: number) => {
    if (indexes.includes(index)) {
      setIndexes(indexes.filter((i) => i !== index));
    } else {
      setIndexes([...indexes, index]);
    }
  };
  const handleIndexChangeDesktop = (index: number) => {
    if (indexes.includes(index)) {
      setIndexes(
        indexes.filter((i) => Math.floor(i / 2) !== Math.floor(index / 2))
      );
    } else {
      setIndexes([...indexes, index, index % 2 === 0 ? index + 1 : index - 1]);
    }
  };
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  useEffect(() => {
    loginJWT(clientJWT || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);
  return (
    <>
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <>
          <VStack align={"flex-start"}>
            {!user?.emailConfirmed || !user?.email ? (
              <Alert status="warning">
                <AlertIcon />
                You didn&#39;t confirm your email, please check your inbox!
              </Alert>
            ) : null}
            <HStack
              w="100%"
              py={8}
              px={[2, 16]}
              justify="center"
              align="center"
            >
              <Heading>Home</Heading>
              <Spacer />
              <Box>
                <TransactionModal
                  submitProps={submit}
                  idProps={id}
                  setIdProps={setId}
                />
              </Box>
            </HStack>
            <Flex
              direction={["column-reverse", "row"]}
              px={[2, 16]}
              align="center"
              justify="space-between"
              w="100%"
              fontSize={20}
            >
              <VStack
                align={["center", "start"]}
                w="100%"
                pt={[4, 0]}
                justify="start"
                px={[2, 16]}
              >
                <Box fontSize={[20, 24]} fontWeight={"bold"}>
                  Expense Analysis
                </Box>
                <Box>
                  <Text fontSize={18}>
                    Percentage Change:{" "}
                    <Box
                      as="span"
                      fontWeight="bold"
                      color={
                        userExpenseAnalysis &&
                        userExpenseAnalysis.percentageChange >= 0
                          ? "green"
                          : "red"
                      }
                    >
                      {userExpenseAnalysis &&
                        userExpenseAnalysis.percentageChange.toFixed(2)}
                      %
                    </Box>
                  </Text>
                </Box>
                <Box>
                  <Text fontSize={18} textAlign={"center"}>
                    Predicted Spend for Current Month:{" "}
                    <Box as="span" fontWeight="bold">
                      {userExpenseAnalysis &&
                        userExpenseAnalysis.monthPrediction.toFixed(2)}{" "}
                      RON
                    </Box>
                  </Text>
                </Box>
              </VStack>
              <VStack align={["center", "start"]} justify="start" pt={[4, 0]}>
                <HStack>
                  <Box> Bank: </Box>
                  <Box fontWeight={"bold"}> {user?.bank?.toFixed(1)}</Box>{" "}
                  <Box>RON</Box>
                </HStack>
                <HStack>
                  <Box> Cash:</Box>{" "}
                  <Box fontWeight={"bold"}>{user?.cash?.toFixed(1)} </Box>{" "}
                  <Box>RON</Box>
                </HStack>
                {user?.binanceBalanceInRon !== 0 ? (
                  <HStack>
                    <Box> Binance:</Box>{" "}
                    <Box fontWeight={"bold"}>
                      {user?.binanceBalanceInRon?.toFixed(1)}{" "}
                    </Box>{" "}
                    <Box>RON</Box>
                  </HStack>
                ) : (
                  <></>
                )}
                <HStack>
                  <Box>Total:</Box>
                  <Box fontWeight={"bold"}>
                    {(
                      (user?.bank || 0) +
                      (user?.cash || 0) +
                      (user?.binanceBalanceInRon || 0)
                    ).toFixed(1)}
                  </Box>
                  <Box>RON</Box>
                </HStack>
              </VStack>
            </Flex>

            {/* User Expense Analysis Section */}

            {transactions.length === 0 ? (
              <Heading
                w="100%"
                alignItems={"center"}
                display="flex"
                justifyContent={"center"}
              >
                <InfoOutlineIcon mr={2} />
                <Text fontSize={[16, 24]}>
                  You have no transaction in the last month!
                </Text>
              </Heading>
            ) : (
              <>
                <VStack w="100%" align="center" pb={20} pt={8} px={[2, 0]}>
                  <Box fontSize={[20, 24]} fontWeight={"bold"} pb={4}>
                    Transactions in the last month
                  </Box>
                  <Accordion
                    allowMultiple
                    defaultIndex={[]}
                    index={indexes}
                    w="100%"
                  >
                    <Grid
                      templateColumns={["1fr", "1fr", "1fr", "2fr 2fr"]}
                      w="100%"
                      rowGap={4}
                      columnGap={2}
                      px={[0, 16]}
                    >
                      {transactions.map((item, index) => (
                        <Fragment key={item.id + "transaction-row"}>
                          <AccordionItem
                            w="100%"
                            onClick={() => {
                              !isLargerThan1280
                                ? handleIndexChangeMobile(index)
                                : handleIndexChangeDesktop(index);
                            }}
                            border="2px solid"
                            pb={[2, 0]}
                            bg={item.type === 10 ? "lightGreen.1" : "red.10"}
                            borderRadius={12}
                          >
                            <TransactionRow
                              transaction={item}
                              handleDelete={handleDelete}
                              handleEdit={handleEdit}
                            />
                          </AccordionItem>
                        </Fragment>
                      ))}
                    </Grid>
                  </Accordion>
                </VStack>
              </>
            )}
          </VStack>
        </>
      )}
    </>
  );
};
