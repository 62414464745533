import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  Icon,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React from "react";
interface CopyRightModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export const CopyRightModal: React.FC<CopyRightModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent
        maxH={"80vh"}
        pr={4}
        overflowY="scroll"
        borderBottom={"8px solid white"}
      >
        <ModalHeader
          bg={"purple.1"}
          h={12}
          w="100%"
          position="sticky"
          top={0}
          zIndex={2}
        >
          <HStack pb={2}>
            <Box>Terms and conditions</Box>
            <Spacer />
            <Icon
              as={CloseIcon}
              onClick={onClose}
              position="sticky"
              zIndex={2}
              mb={2}
            />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <Text
            fontSize={20}
            textAlign="center"
            fontWeight={"bold"}
            color="purple.300"
          >
            {" "}
            Welcome to VeSeFe.
          </Text>{" "}
          <br />
          <Text textIndent={"25px"} textAlign="justify">
            By using my website, you agree to the following terms and
            conditions. Please read these Terms carefully before using our
            website. If you do not agree to these Terms, you may not use our
            website.
          </Text>
          <Text textIndent={"25px"} textAlign="justify">
            In order to use the website you need to be confirmed by an admin,
            but for testing purpose only, you can insert the word &#34;text&#34;
            anywhere in the field &#34;Reason&#34; and the account will
            automatically be confirmed by the system, after 24h the account will
            be deleted.
          </Text>
          <Text textIndent={"25px"} fontSize={16}>
            Please feel free to contact me at{" "}
            <Link href="mailto: admin@vesefe.com" target={"_blank"}>
              {" "}
              admin@vesefe.com
            </Link>
            .
          </Text>
          <OrderedList>
            <ListItem>
              <Text fontSize={16} fontWeight={"bold"} color="purple.300">
                Use of Service
              </Text>
              <OrderedList listStyleType={"lower-alpha"}>
                <ListItem>
                  <Text color="purple.200">Eligibility:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    To use our Service, you must be at least 18 years of age or
                    the legal age of majority in your jurisdiction, whichever is
                    greater. By using our Service, you represent and warrant
                    that you meet these eligibility requirements.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="purple.200">Registration:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    To use certain features of our Service, you may be required
                    to register for an account with us. You agree to provide
                    accurate, current, and complete information during the
                    registration process and to update such information as
                    necessary to ensure that it remains accurate, current, and
                    complete.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="purple.200">Prohibited Activities:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    You agree not to use our Service for any unlawful or
                    fraudulent activities, including but not limited to money
                    laundering or terrorist financing. You also agree not to use
                    our Service to engage in any activity that violates these
                    Terms or any applicable laws.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="purple.200">Service Availability:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    We reserve the right to modify, suspend, or discontinue our
                    Service at any time and for any reason, with or without
                    notice.
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              <Text fontSize={16} fontWeight={"bold"} color="purple.300">
                User Content
              </Text>
              <OrderedList listStyleType={"lowerAlpha"}>
                <ListItem>
                  <Text color="purple.200">Ownership:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    You retain ownership of any content that you post, upload,
                    or otherwise submit to our Service (“User Content”).
                    However, you grant us a non-exclusive, royalty-free,
                    transferable, sub-licensable, worldwide license to use,
                    display, reproduce, modify, distribute, and create
                    derivative works of your User Content in connection with our
                    Service. We also keep all User Content that you post,
                    upload, or otherwise submit to our Service, even after you
                    delete it. If you want to permanently delete it you can
                    contact us.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="purple.200">Responsibility:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    You are solely responsible for your User Content, and you
                    represent and warrant that you have all necessary rights to
                    post, upload, or otherwise submit such User Content to our
                    Service. You also agree that your User Content will not
                    violate any third-party rights, including but not limited to
                    intellectual property rights, privacy rights, or publicity
                    rights.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="purple.200">Removal:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    We reserve the right to remove any User Content that
                    violates these Terms or any applicable laws, or that we
                    believe in good faith may create liability for us.
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              Financial Information
              <OrderedList listStyleType={"lowerAlpha"}>
                <ListItem>
                  <Text color="purple.200">Accuracy:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    We make no representation or warranty as to the accuracy,
                    completeness, or timeliness of any financial information
                    provided through our Service. You acknowledge and agree that
                    you are solely responsible for verifying the accuracy of any
                    financial information before relying on it.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="purple.200">No Investment Advice:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    Our Service is for informational purposes only and should
                    not be construed as investment advice or a recommendation to
                    buy or sell any securities or other financial products. You
                    acknowledge and agree that you are solely responsible for
                    making your own investment decisions, and that you will seek
                    the advice of a qualified professional before making any
                    investment.
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              Fees and Payments
              <OrderedList>
                <ListItem>
                  <Text color="purple.200">Fees:</Text>I won&#39;t charge the
                  user for any service.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              Intellectual Property
              <OrderedList listStyleType={"lowerAlpha"}>
                <ListItem>
                  <Text color="purple.200">Ownership:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    Our Service, including all content, software, technology,
                    designs, trademarks, and other intellectual property, is
                    owned by me. You acknowledge and agree that my Service is
                    protected by copyright, trademark, and other laws.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="purple.200">Use:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    You may use our Service only for your personal and
                    non-commercial use. You agree not to reproduce, modify,
                    distribute, display, or otherwise exploit our Service, or
                    any content, software, technology, designs, trademarks, or
                    other intellectual property, except as expressly authorized
                    by us in writing.
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              Disclaimer of Warranties
              <OrderedList>
                <ListItem>
                  <Text color="purple.200">No Investment Advice:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    OUR SERVICE IS FOR INFORMATIONAL PURPOSES ONLY AND SHOULD
                    NOT BE CONSTRUED AS INVESTMENT ADVICE OR A RECOMMENDATION TO
                    BUY OR SELL ANY SECURITIES OR OTHER FINANCIAL PRODUCTS. YOU
                    ACKNOWLEDGE AND AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR
                    MAKING YOUR OWN INVESTMENT DECISIONS, AND THAT YOU WILL SEEK
                    THE ADVICE OF A QUALIFIED PROFESSIONAL BEFORE MAKING ANY
                    INVESTMENT.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="purple.200">No Guarantee:</Text>
                  <Text textIndent={"25px"} textAlign="justify">
                    WE MAKE NO GUARANTEE THAT ANY INVESTMENT WILL ACHIEVE ITS
                    OBJECTIVES, GENERATE PROFITS, OR AVOID LOSSES. YOU
                    ACKNOWLEDGE AND AGREE THAT ALL INVESTMENTS INVOLVE RISK, AND
                    THAT YOU ARE SOLELY RESPONSIBLE FOR ANY LOSSES YOU MAY
                    INCUR.
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>
          </OrderedList>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
