import { useEffect, useState } from "react";
import { apiClient, authorise } from "../../apiClient";
import { StatisticsInterface } from "../Statistics/types";
import { CategoryRowData, ExtendedStatisticInterface } from "./types";

export const useExtendedStatistic = (
  statisticId: string
): ExtendedStatisticInterface => {
  const [loading, setLoading] = useState<boolean>(true);
  const [statistic, setStatistic] = useState<StatisticsInterface | null>(null);
  const [categories, setCategories] = useState<CategoryRowData[]>([]);
  const getData = async () => {
    const { statistic, categories } = await apiClient
      .get(
        `/api/Statistics/get-statistics-by-id?id=${statisticId}`,
        authorise()
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setStatistic(statistic);
    setCategories(categories);
    setLoading(false);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticId]);
  return { loading, statistic, categories };
};
