export type RecordType = Record<number, string>;

export const TransactionType: RecordType = {
  20: "Expense",
  10: "Income",
};
export const DepositType: RecordType = {
  10: "Bank",
  20: "Cash",
};
export const FrequencyType: RecordType = {
  10: "Daily",
  20: "Weekly",
  30: "Monthly",
  40: "Yearly",
};
export interface TransactionInterface {
  id?: string;
  name: string;
  description?: string;
  amount: string;
  type: number;
  deposit: number;
  currentAmountBank: string;
  currentAmountCash: string;
  date: string;
  frequency?: number;
  startDate?: string;
  isRecurent: boolean;
  currentBinanceBalanceInRon?: number;
  category?: string;
}

export interface UserExpenseAnalysisInterface {
  percentageChange: number;
  monthPrediction: number;
}
