import moment from "moment";
import { createContext } from "react";
import { isEmpty } from "../../../utils/helpers";
import { ErrorTransactionForm } from "../../Home/types";
import { FormIdDictionary } from "../../Settings/UserModal/useUserModal";
import { TransactionInterface } from "../Table/types";
import { SelectOptionsInterface } from "../VSFDropdown";
import { TransactionModalContextInterface } from "./types";

export const TransactionFormId: FormIdDictionary = {
  name: "id-transaction-name",
  description: "id-transaction-description",
  type: "id-transaction-type",
  deposit: "id-transaction-deposit",
  amount: "id-transaction-amount",
  frequency: "id-transaction-frequency",
  date: "id-transaction-date",
  isRecurring: "id-transaction-isRecurring",
  category: "id-transaction-category",
};

export const defaultError: ErrorTransactionForm = {
  amount: "",
  type: "",
  name: "",
  deposit: "",
  date: "",
  frequency: "",
  isRecurent: "",
};

export const defaultData: TransactionInterface = {
  name: "",
  currentAmountBank: "",
  currentAmountCash: "",
  amount: "",
  type: 0,
  deposit: 0,
  frequency: 0,
  date: moment().toISOString(),
  isRecurent: false,
};

export const TransactionModalContext =
  createContext<TransactionModalContextInterface>(
    {} as TransactionModalContextInterface
  );

export const transactionOptions: SelectOptionsInterface[] = [
  { value: "10", display: "Income" },
  { value: "20", display: "Expense" },
];
export const depositOptions: SelectOptionsInterface[] = [
  { value: "10", display: "Bank" },
  { value: "20", display: "Cash" },
];
export const frequencyOptions: SelectOptionsInterface[] = [
  { value: "10", display: "Daily" },
  { value: "20", display: "Weekly" },
  { value: "30", display: "Monthly" },
  { value: "40", display: "Yearly" },
];

////////////////////////// FUNCTIONS //////////////////////

interface FieldValue {
  value: any;
  exchangeValue?: any;
}

interface ErrorCheckParams {
  fields: Record<string, FieldValue>;
  isRecurent: boolean;
  isExchange: boolean;
}
