import { Box, Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { isEmpty } from "../../utils/helpers";
import { NameWrap } from "../common/NameWrap";
import { VSFButton } from "../common/VSFButton";
import { VSFInput } from "../common/VSFInput";
import { AuthenticationWrapper } from "../Dashboard/AuthenticationWrapper";
import { useForgotPassword } from "./useForgotPassword";
export const ForgotPassword: React.FC = () => {
  const { email, setEmail, submit, error, setError, isLoading } =
    useForgotPassword();
  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError(isEmpty(e.target.value));
  };
  return (
    <>
      <AuthenticationWrapper>
        <VStack
          overflow={"auto"}
          align="center"
          bg="white"
          p={4}
          position={"relative"}
          border="2px solid"
          borderColor={"purple.400"}
          borderRadius={4}
          // p={4}
        >
          <Flex
            p={4}
            px={20}
            position="sticky"
            top={0}
            w="100%"
            direction="column"
            align={"center"}
            bg="white"
            zIndex={2}
          >
            <Box fontSize={[16, 20]} whiteSpace="nowrap" fontWeight={"bold"}>
              Reset your password
            </Box>
          </Flex>
          <NameWrap title="Token" error={error} w="100%" isRequired>
            <VSFInput
              value={email}
              error={error}
              onChange={handleChangeEmail}
              placeholder="Enter email"
            />
          </NameWrap>

          <Flex
            position={"sticky"}
            bottom="0"
            bg="white"
            align="center"
            direction={"column"}
            w="100%"
          >
            <VSFButton onClick={submit} isLoading={isLoading} w="90%" py={2}>
              Submit
            </VSFButton>
          </Flex>
        </VStack>
      </AuthenticationWrapper>
    </>
  );
};
