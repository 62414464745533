import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
interface VSFDatePickerInterface extends Omit<InputProps, "onChange"> {
  onChange: (date: string | null) => void;
  error?: string;
  value: string;
}
export const VSFDatePicker: React.FC<VSFDatePickerInterface> = (props) => {
  const { onChange, error, value, ...others } = props;
  const [errorDate, setError] = useState<boolean>(true);
  const [inputDate, setDate] = useState<string>(
    value ?? `${moment.utc().toISOString()}`
  );
  const dateToIso = (date: string) => {
    return moment.utc(date).toISOString();
  };
  const isoToDate = (date: string) => {
    return moment.utc(date).format("YYYY-MM-DD");
  };

  function dateIsValid(date: string) {
    const d = moment(date);
    return d.isValid();
  }
  useEffect(() => {
    if (value) onChange(isoToDate(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (inputDate) {
      setError(dateIsValid(inputDate));
      onChange(moment.utc(inputDate).toISOString());
    } else onChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDate]);
  const dateChangeHandler = (e: React.ChangeEvent<HTMLDataElement>) => {
    setDate(dateToIso(e.currentTarget.value));
  };
  return (
    <Box h="100%">
      <InputGroup>
        <Input
          placeholder="Select Date and Time..."
          size={"lg"}
          type="date"
          borderWidth={1}
          // css={`
          //   ::-webkit-calendar-picker-indicator {
          //     background: url(https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-16.png)
          //       center/80% no-repeat;
          //     color: black;
          //   }
          // `}
          borderRadius={4}
          borderColor={errorDate || error ? "gray.300" : "danger.500"}
          focusBorderColor={errorDate && error ? "danger.500" : "blue.800"}
          {...others}
          value={value ? moment(value).format("YYYY-MM-DD") : ""}
          onChange={dateChangeHandler}
        />
      </InputGroup>
    </Box>
  );
};
