import { Td, Text, Tr } from "@chakra-ui/react";
import React from "react";
import { CategoryRowData } from "./types";

export const CategoryRow: React.FC<CategoryRowData> = ({
  amount,
  type,
  name,
  index,
}) => {
  const textColor = type === 10 ? "green.500" : "red.500";

  return (
    <Tr mb={[0, 2]} w="60%" border={"2px solid"} borderColor="purple.500">
      <Td isNumeric borderRight={"2px solid"}>
        <Text fontSize={["md", "lg"]} fontWeight="bold" color={textColor}>
          {index}.
        </Text>
      </Td>
      <Td borderRight={"2px solid"}>
        <Text fontSize={["md", "lg"]}>{name}</Text>
      </Td>
      <Td borderRight={"2px solid"}>
        <Text fontSize={["md", "lg"]}>{type === 10 ? "Bank" : "Cash"}</Text>
      </Td>
      <Td isNumeric>
        <Text fontSize={["md", "lg"]} fontWeight="bold" color={textColor}>
          {type === 10 ? "+" : "-"} {Math.abs(amount)} RON
        </Text>
      </Td>
    </Tr>
  );
};
