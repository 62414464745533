import { Button, HStack, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { theme } from "../../theme";
import { ChartViewEnum, StatisticsContext } from "./types";
const SwitchButton = ({ chartView, setChartView, viewEnum }: any) => {
  const isActive = chartView === viewEnum;
  const bgColor = isActive ? "purple.500" : "purple.200";
  const color = useColorModeValue("white", "gray.800");

  return (
    <Button
      onClick={() => setChartView(viewEnum)}
      bgColor={bgColor}
      color={color}
      borderRadius="0"
      _hover={{
        bgColor: theme.colors.purple[600],
      }}
      fontSize={["xs", "md"]}
      transition="background-color 0.3s ease"
    >
      {viewEnum === ChartViewEnum.MonthlyOverview
        ? "Monthly Overview"
        : "Financial Position & Growth"}
    </Button>
  );
};
export const ViewSwitch: React.FC = () => {
  const { chartView, setChartView } = React.useContext(StatisticsContext);
  return (
    <HStack spacing={0}>
      <SwitchButton
        chartView={chartView}
        setChartView={setChartView}
        viewEnum={ChartViewEnum.MonthlyOverview}
      />
      <SwitchButton
        chartView={chartView}
        setChartView={setChartView}
        viewEnum={ChartViewEnum.FinancialPosition}
      />
    </HStack>
  );
};
