import { useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiClient, authorise } from "../../../apiClient";
import { ErrorContext } from "../../../App";
import { isEmpty } from "../../../utils/helpers";
import { ErrorTransactionForm } from "../../Home/types";
import { TransactionInterface } from "../Table/types";
import {
  TransactionExhangeInterface,
  TransactionModalContextInterface,
} from "./types";
import { defaultData, defaultError, TransactionFormId } from "./utils";

export const useTransaction = (
  submitProps: (data: TransactionInterface) => void,
  idProps?: string,
  setIdProps?: (id: string) => void
): TransactionModalContextInterface => {
  const { createError } = useContext(ErrorContext);
  const [id, setId] = useState<string>(idProps || "");
  const location = useLocation();
  const [data, setData] = useState<TransactionInterface>({
    ...defaultData,
    isRecurent: location.pathname.includes("setting"),
  });
  const [isExchange, setIsExchange] = useState<boolean>(false);
  const [dataExchange, setDataExchange] = useState<TransactionExhangeInterface>(
    {} as TransactionExhangeInterface
  );
  const [error, setError] = useState<ErrorTransactionForm>(defaultError);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const createErrorObject = (): ErrorTransactionForm => {
    return {
      amount: isExchange
        ? isEmpty((data.amount || dataExchange.amount).toString())
        : isEmpty(data.amount.toString()),

      type: isExchange
        ? isEmpty(
            data.type === 0 || dataExchange.type === 0
              ? ""
              : (data.type || dataExchange.type)?.toString() || ""
          )
        : isEmpty(data.type === 0 ? "" : data.type?.toString()),
      name: isEmpty(data.name),
      deposit: isExchange
        ? isEmpty(
            data.deposit === 0 || dataExchange.deposit === 0
              ? ""
              : (data.deposit || dataExchange.deposit)?.toString()
          )
        : isEmpty(data.deposit === 0 ? "" : data.deposit?.toString()),
      description: "",
      date: isEmpty(data.date),
      frequency: data.isRecurent
        ? isEmpty(data.frequency === 0 ? "" : data.frequency?.toString() || "")
        : "",
      isRecurent: "",
    };
  };

  const validateForm = () => {
    const errorObject = createErrorObject();
    if (
      Object.values(errorObject).every((x) => {
        return x === "";
      })
    ) {
      return true;
    } else {
      setError(errorObject);
      for (const key in errorObject) {
        if (errorObject.hasOwnProperty(key) && errorObject[key] !== "") {
          const elementId = TransactionFormId[key];
          document.getElementById(elementId || "")?.focus();
          break;
        }
      }
      return false;
    }
  };

  const initializeEdit = async () => {
    setIsLoading(true);
    onOpen();
    await apiClient
      .get(
        data.isRecurent
          ? `/api/Transaction/get-recurrent-transaction?id=${idProps}`
          : `/api/Transaction/get-transaction?id=${idProps}`,
        authorise()
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        createError(err.data);
      });
    setIsLoading(false);
  };

  const handleDataChange = (newData: Partial<TransactionInterface>) => {
    setData({ ...data, ...newData });
  };
  const handleDataExchangeChange = (
    newData: Partial<TransactionExhangeInterface>
  ) => {
    setDataExchange({ ...dataExchange, ...newData });
  };
  const handleErrorChange = (newError: Partial<ErrorTransactionForm>) => {
    setError({ ...error, ...newError });
  };

  const reset = () => {
    onClose();
    setIsExchange(false);
    setDataExchange({} as TransactionExhangeInterface);
    setData(defaultData);
    setError(defaultError);
    setId("");
    setIsSubmitting(false);
    setIdProps?.("");
  };

  const toggleExchange = () => {
    setIsExchange(!isExchange);
  };
  const { isOpen, onClose, onOpen } = useDisclosure();
  const submit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      if (validateForm()) {
        setIsSubmitting(true);
        submitProps(data);
        if (isExchange)
          submitProps({
            ...data,
            ...dataExchange,
          });
        reset();
      }
    }
  };

  useEffect(() => {
    if (id) {
      initializeEdit();
    }
  }, [id]);

  useEffect(() => {
    setId(idProps || "");
  }, [idProps]);

  return {
    reset,
    data,
    error,
    isOpen,
    onClose: reset,
    onOpen,
    submit,
    isLoading,
    isSubmitting,
    handleErrorChange,
    idProps,
    handleDataChange,
    toggleExchange,
    isExchange,
    dataExchange,
    handleDataExchangeChange,
  };
};
