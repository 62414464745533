import { Box, Button, Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { isEmpty } from "../../utils/helpers";
import { NameWrap } from "../common/NameWrap";
import { VSFButton } from "../common/VSFButton";
import { VSFInput } from "../common/VSFInput";
import { VSFPasswordInput } from "../common/VSFPasswordInput";
import { VSFTextArea } from "../common/VSFTextArea";
import { AuthenticationWrapper } from "../Dashboard/AuthenticationWrapper";
import { RegisterFormId, useRegister } from "./useRegister";
export const Register: React.FC = () => {
  const {
    data,
    handleData,
    handleRegister,
    setPasswords,
    passwords,
    error,
    setError,
    handleLogin,
    isLoading,
  } = useRegister();

  const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleData({ username: e.target.value });
    setError({ ...error, username: isEmpty(e.target.value) });
  };
  const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleData({ firstName: e.target.value });

    setError({ ...error, firstName: isEmpty(e.target.value) });
  };
  const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleData({ lastName: e.target.value });
    setError({ ...error, lastName: isEmpty(e.target.value) });
  };
  const handlePasswordChange = (e: string) => {
    setPasswords({ ...passwords, password: e });
    setError({ ...error, password: isEmpty(e) });
  };

  const handleConfirmPasswordChange = (e: string) => {
    setPasswords({ ...passwords, confirmPassword: e });
    setError({ ...error, confirmPassword: isEmpty(e) });
  };
  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleData({ email: e.target.value });
    setError({ ...error, email: isEmpty(e.target.value) });
  };
  const handleChangeReason = (e: string) => {
    handleData({ reason: e });
    setError({ ...error, reason: isEmpty(e) });
  };
  return (
    <AuthenticationWrapper>
      <>
        <VStack
          overflow={"auto"}
          align="center"
          bg="white"
          w={["100%", "90%"]}
          position={"relative"}
          border="2px solid"
          borderColor={"purple.400"}
          borderRadius={4}
          // p={4}
        >
          <Flex
            p={4}
            position="sticky"
            top={0}
            w="100%"
            direction="column"
            align={"center"}
            bg="white"
            zIndex={2}
          >
            <Box fontSize={24} fontWeight={"bold"}>
              Register
            </Box>
          </Flex>
          <NameWrap title="Username" error={error.username} w="90%" isRequired>
            <VSFInput
              id={RegisterFormId.username}
              value={data.username}
              error={error.username}
              onChange={handleChangeUsername}
              placeholder="Enter username"
            />
          </NameWrap>
          <NameWrap
            title="Firstname"
            error={error.firstName}
            isRequired
            w="90%"
          >
            <VSFInput
              id={RegisterFormId.firstName}
              value={data.firstName}
              error={error.username}
              onChange={handleChangeFirstName}
              placeholder="Enter firstname"
            />
          </NameWrap>
          <NameWrap title="Lastname" error={error.lastName} isRequired w="90%">
            <VSFInput
              id={RegisterFormId.lastName}
              value={data.lastName}
              error={error.lastName}
              onChange={handleChangeLastName}
              placeholder="Enter username"
            />
          </NameWrap>
          <NameWrap title="Email" error={error.email} w="90%" isRequired>
            <VSFInput
              id={RegisterFormId.email}
              value={data.email}
              error={error.email}
              onChange={handleChangeEmail}
              placeholder="Enter email"
            />
          </NameWrap>
          <NameWrap title="Reason to register" error={error.reason} w="90%">
            <VSFTextArea
              id={RegisterFormId.reason}
              value={data.reason}
              error={error.reason}
              onChange={handleChangeReason}
              placeholder="Enter reason to register..."
            />
          </NameWrap>
          <NameWrap
            title="Password"
            error={error.password}
            isRequired
            pb={4}
            w="90%"
          >
            <VSFPasswordInput
              id={RegisterFormId.password}
              password={passwords.password}
              handlePasswordChange={handlePasswordChange}
              error={error.password}
            />
          </NameWrap>
          <NameWrap
            title="Confirm Password"
            error={error.confirmPassword}
            pb={4}
            isRequired
            w="90%"
          >
            <VSFPasswordInput
              id={RegisterFormId.confirmPassword}
              password={passwords.confirmPassword}
              handlePasswordChange={handleConfirmPasswordChange}
              error={error.confirmPassword}
            />
          </NameWrap>
          <Flex
            position={"sticky"}
            bottom="0"
            bg="white"
            align="center"
            direction={"column"}
            w="100%"
          >
            <VSFButton
              onClick={handleRegister}
              isLoading={isLoading}
              w="90%"
              py={2}
            >
              Register
            </VSFButton>

            <Box pt={0.5} pb={2} fontSize={12} display="inline-block">
              *You already have an account? Login
              <Button
                display="inline-block"
                p={0}
                onClick={handleLogin}
                variant={"link"}
                fontSize={12}
                color={"purple.100"}
              >
                {" "}
                here.{" "}
              </Button>{" "}
            </Box>
          </Flex>
        </VStack>
      </>
    </AuthenticationWrapper>
  );
};
