import {
  Box,
  Flex,
  Heading,
  Table,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import { CustomSpinner } from "../common/CustomSpinner";
import { CategoryRow } from "./CategoryRow";
import { useExtendedStatistic } from "./useExtendedStatistic";
export const ExtendedStatistic: React.FC = () => {
  const { statisticId } = useParams();
  const { loading, statistic, categories } = useExtendedStatistic(
    statisticId || ""
  );
  return (
    <>
      {loading ? (
        <CustomSpinner />
      ) : (
        <Flex w="100%" justify={"center"}>
          <VStack
            spacing={6}
            width={["100%", "60%"]}
            alignItems="flex-start"
            p={[8, 0]}
          >
            <Heading size="lg">
              Statistics for {moment(statistic?.date).format("MMMM yyyy")}
            </Heading>
            <Flex
              justifyContent="space-between"
              alignItems="start"
              direction={["column", "row"]}
              width="100%"
              mb={6}
            >
              <Flex direction={["column", "row"]}>
                <Text fontSize="lg" fontWeight="bold">
                  Total Expenses: {statistic?.totalExpenses}
                </Text>
                <Text fontSize="lg" fontWeight="bold">
                  Total Incomes: {statistic?.totalIncomes}
                </Text>
              </Flex>
              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  Current Cash: {statistic?.currentCash}
                </Text>
                <Text fontSize="lg" fontWeight="bold">
                  Current Bank: {statistic?.currentBank}
                </Text>
              </Box>
              <Box>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color={
                    statistic?.changePercent || 1 >= 0 ? "green.500" : "red.500"
                  }
                >
                  Change Percent: {statistic?.changePercent}%
                </Text>
              </Box>
            </Flex>
            <Flex justify={"center"} w="100%">
              <Table w="100%" border={"2px solid"} borderColor="purple.500">
                <Thead bg="purple.500">
                  <Tr>
                    <Th
                      isNumeric
                      fontSize={[12, 20]}
                      fontWeight={"bold"}
                      color="white"
                    >
                      Index
                    </Th>
                    <Th fontSize={[12, 20]} fontWeight={"bold"} color="white">
                      Category
                    </Th>
                    <Th fontSize={[12, 20]} fontWeight={"bold"} color="white">
                      Type
                    </Th>
                    <Th
                      isNumeric
                      fontSize={[12, 20]}
                      fontWeight={"bold"}
                      color="white"
                    >
                      Amount
                    </Th>
                  </Tr>
                </Thead>
                {categories.map((category, index) => (
                  <CategoryRow
                    key={index}
                    index={index}
                    amount={category.amount}
                    type={category.type}
                    name={category.name}
                  />
                ))}
              </Table>
            </Flex>
          </VStack>
        </Flex>
      )}
    </>
  );
};
