import {
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { ReactComponent as Logout } from "../../Assets/Icons/Logout.svg";
export const NavBarHeight: number = 16;

export const NavBar: React.FC = () => {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const navBarRef = React.useRef<HTMLDivElement>(null);
  const image = "/PurpleLogo.png";
  const hoverColor = "purple.200";
  const handleClick = (path: string) => navigate(path);
  const NavBarItem = ({ path, text }: { path: string; text: string }) => {
    return (
      <Button
        opacity={location.pathname === path ? 1 : 0.5}
        variant={"link"}
        onClick={() => handleClick(path)}
        _hover={{ color: hoverColor, textDecoration: "underline" }}
        fontSize={[16, 20]}
        color="purple.1"
        pr={4}
      >
        {text}
      </Button>
    );
  };

  return (
    <VStack
      w="100%"
      spacing={0}
      top={0}
      zIndex={10}
      position={["fixed", "static"]}
    >
      <HStack
        w="100%"
        ref={navBarRef}
        h={NavBarHeight}
        fontWeight="bold"
        borderBottom={"1px solid"}
        bgGradient={"linear(to-r, purple.500, purple.200)"}
        borderColor={"purple.400"}
        pl={[4, 0]}
      >
        {/* <HambugerIcon navBarHeight={navBarRef.current?.clientHeight} /> */}
        <Spacer display={["flex", "none"]} />

        <Box
          cursor={"pointer"}
          onClick={() =>
            user?.userRole === 20 ? handleClick("/home") : handleClick("/users")
          }
          pl={[8, 0]}
          pr={[0, 4]}
        >
          <Image src={image} h={16} w={16} />
        </Box>
        <Box display={["none", "contents"]}>
          {user ? (
            user.userRole === 20 ? (
              <>
                <NavBarItem path="/home" text="Home" />
                <NavBarItem path="/history" text="History" />
                <NavBarItem path="/statistics" text="Statistics" />
                <NavBarItem path="/settings" text="Settings" />
              </>
            ) : (
              <>
                {" "}
                <NavBarItem path="/users" text="Users" />
              </>
            )
          ) : (
            <>
              <NavBarItem path="/login" text="Login" />
              <NavBarItem path="/register" text="Register" />
            </>
          )}
        </Box>

        <Spacer />
        {user ? (
          <Box cursor="pointer" onClick={() => logout()}>
            <Icon as={Logout} w={6} h={6} mr={4} color="purple.1" />
          </Box>
        ) : null}
      </HStack>
    </VStack>
  );
};
