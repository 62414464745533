import { CloseIcon } from "@chakra-ui/icons";
import { Box, HStack, Icon, ModalHeader, Spacer } from "@chakra-ui/react";
import React, { useContext } from "react";
import { TransactionModalContext } from "./utils";
interface TransactionModalHeaderProps {
  isEdit: boolean;
}
export const TransactionModalHeader: React.FC<TransactionModalHeaderProps> = ({
  isEdit,
}) => {
  const { onClose } = useContext(TransactionModalContext);
  return (
    <ModalHeader
      bg={"purple.1"}
      h={12}
      w="100%"
      position="sticky"
      top={0}
      zIndex={2}
    >
      <HStack>
        <Box>{isEdit ? "Edit transaction" : "Add transaction"}</Box>
        <Spacer />
        <Icon
          as={CloseIcon}
          onClick={onClose}
          position="sticky"
          zIndex={2}
          mb={2}
        />
      </HStack>
    </ModalHeader>
  );
};
