import { useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { apiClient, authorise } from "../../apiClient";
import { ErrorContext } from "../../App";
import {
  TransactionInterface,
  UserExpenseAnalysisInterface,
} from "../common/Table/types";
import { HomeContextInterface } from "./types";

export const useHome = (): HomeContextInterface => {
  const {
    isOpen: isOpenInitializeModal,
    onClose: onCloseInitializeModal,
    onOpen: onOpenInitializeModal,
  } = useDisclosure();

  const { createError, createToast } = useContext(ErrorContext);

  const [transactions, setTransactions] = useState<TransactionInterface[]>([]);

  const [userExpenseAnalysis, setUserExpenseAnalysis] =
    useState<UserExpenseAnalysisInterface | null>(null);

  const [id, setId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const submit = async (data: TransactionInterface) => {
    try {
      let response;

      if (id) {
        // Update transaction
        response = await apiClient.put(
          `/api/Transaction/update-transaction?id=${id}`,
          data,
          authorise()
        );
        createToast("Transaction updated");
      } else if (data.isRecurent) {
        // Create recurrent transaction
        response = await apiClient.post(
          "/api/Transaction/create-recurrent-transaction",
          data,
          authorise()
        );
        createToast("Recurrent transaction created");
      } else {
        // Create transaction
        response = await apiClient.post(
          "/api/Transaction/create-transaction",
          data,
          authorise()
        );
        createToast("Transaction created");
        updateTransactionsState(response.data);
      }

      getData();
    } catch (error) {
      handleError(error);
    }
  };

  function updateTransactionsState(newTransaction: TransactionInterface) {
    setTransactions((prevTransactions) => {
      const updatedTransactions = [...prevTransactions, newTransaction];
      return updatedTransactions.sort((a, b) =>
        moment(b.date, "DD/MM/YYYY").diff(moment(a.date, "DD/MM/YYYY"))
      );
    });
  }

  function handleError(error: any) {
    createError(Array.isArray(error?.data) ? error.data : [error?.data]);
  }

  const getData = async () => {
    await apiClient
      .get("/api/Transaction/get-last-month-transactions", authorise())
      .then((res) => {
        setTransactions(res.data);
      })
      .catch((error) => {
        createError(Array.isArray(error.data) ? error.data : [error.data]);
      });

    await apiClient
      .get("/api/UserHistory/get-expense-analysis", authorise())
      .then((res) => {
        setUserExpenseAnalysis(res.data);
      })
      .catch((error) => {
        createError(Array.isArray(error.data) ? error.data : [error.data]);
      });
    setIsLoading(false);
  };

  const handleEdit = (id: string) => {
    setId(id);
  };

  const handleDelete = async (id: string) => {
    await apiClient
      .delete(`/api/Transaction/delete-transaction?id=${id}`, authorise())
      .then((res) => {
        createToast("Transaction deleted");
        getData();
      })
      .catch((error) => {
        createError(Array.isArray(error.data) ? error.data : [error.data]);
      });
  };

  useEffect(() => {
    createToast("Welcome");
    getData();
  }, []);

  return {
    id,
    userExpenseAnalysis,
    handleDelete,
    handleEdit,
    isLoading,
    submit,
    transactions,
    isOpenInitializeModal,
    onCloseInitializeModal,
    onOpenInitializeModal,
    setId,
  };
};
