import {
  AccordionButton,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { ReactComponent as Options } from "../../Assets/Icons/Options.svg";
import { ReactComponent as UserIcon } from "../../Assets/Icons/User.svg";
import { UserInterface } from "../../utils/types";
import { NameWrap } from "../common/NameWrap";
interface UserRowInterface {
  user: UserInterface;
  deleteUser: (id: string) => void;
  toggleUser: (id: string) => void;
}
export const UserRow: React.FC<UserRowInterface> = ({
  user,
  deleteUser,
  toggleUser,
}) => {
  return (
    <>
      <AccordionButton w="100%" color="white">
        <Flex
          //         display={["none", "flex"]}
          direction={["column", "row"]}
          w="100%"
          align={["center", "flex-start"]}
          justify={"space-between"}
        >
          <NameWrap title="">
            <Icon as={UserIcon} boxSize={8} />
          </NameWrap>
          <NameWrap
            ml={[0, 2]}
            direction={["row", "column"]}
            title="Name"
            w={["80%", "50%"]}
            fontWeight={"bold"}
          >
            <Text textAlign={["end", "start"]} pr={2}>
              {user.username}
            </Text>
          </NameWrap>

          <NameWrap
            direction={["row", "column"]}
            title="Last Name"
            w={["80%", "50%"]}
            fontWeight={"bold"}
          >
            <Text textAlign={["end", "start"]} pr={2}>
              {user.lastName}
            </Text>
          </NameWrap>
          <NameWrap
            direction={["row", "column"]}
            title="First name"
            w="80%"
            fontWeight={"bold"}
          >
            <Text textAlign={["end", "start"]} pr={2}>
              {user.firstName}
            </Text>
          </NameWrap>
          <NameWrap
            ml={[0, 2]}
            direction={["row", "column"]}
            title="Email"
            w={["80%", "90%"]}
            fontWeight={"bold"}
          >
            <Text
              textAlign={["end", "start"]}
              pr={2}
              maxW="90%"
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow="ellipsis"
            >
              {user.email}
            </Text>
          </NameWrap>
        </Flex>
        <Menu>
          <MenuButton as={Box} cursor={"pointer"}>
            <Icon as={Options} boxSize={8} />
          </MenuButton>
          <MenuList bg="white">
            <MenuItem
              bg="white"
              color="purple.700"
              _hover={{ bg: "purple.10" }}
              onClick={() => toggleUser(user.id || "")}
            >
              Toggle Active
            </MenuItem>

            <MenuItem
              color="red.500"
              bg="white"
              _hover={{ bg: "purple.10" }}
              onClick={() => deleteUser(user.id || "")}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </AccordionButton>
      <AccordionPanel>
        <>
          <VStack pl={10} align="start">
            <Flex
              flexDirection={["column", "row"]}
              color="white"
              w={["100%", "50%"]}
              align={"start"}
            >
              <NameWrap
                direction={["row", "column"]}
                title="Joining date"
                w="80%"
                fontWeight={"bold"}
              >
                <Text textAlign={["end", "start"]} pr={2}>
                  {moment(user.createdAt).format("DD/MM/YYYY")}
                </Text>
              </NameWrap>
              <NameWrap
                direction={["row", "column"]}
                title="Last Login"
                w="80%"
                fontWeight={"bold"}
              >
                <Text textAlign={["end", "start"]} pr={2}>
                  {moment(user.lastLogin)
                    .format("DD/MM/YYYY")
                    .includes("Invalid")
                    ? "None"
                    : moment(user.lastLogin).format("DD/MM/YYYY")}
                </Text>
              </NameWrap>
              <NameWrap
                direction={["row", "column"]}
                title="Is active"
                w="80%"
                fontWeight={"bold"}
              >
                <Text textAlign={["end", "start"]} pr={2}>
                  {user.isActive ? "Yes" : "No"}
                </Text>
              </NameWrap>
              <NameWrap
                direction={["row", "column"]}
                title="Email confirmed"
                w="80%"
                fontWeight={"bold"}
              >
                <Text textAlign={["end", "start"]} pr={2}>
                  {user.emailConfirmed ? "Yes" : "No"}
                </Text>
              </NameWrap>
            </Flex>
            <Box>
              {user.reason && (
                <NameWrap
                  color="white"
                  title="Reason"
                  justify={"start"}
                  w="80%"
                  fontWeight={"bold"}
                >
                  <Box>
                    <Text textAlign={["end", "start"]} pr={2}>
                      {user.reason}
                    </Text>
                  </Box>
                </NameWrap>
              )}
            </Box>
          </VStack>
        </>
      </AccordionPanel>
    </>
  );
};
